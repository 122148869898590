import { useState } from "react";
import { Alert, Card, CardBody, Form, FormGroup, Input, Label } from "reactstrap";
import { login } from "../auth.api";
import { authService } from "../auth.service";
import SapButton from "../../../components/SapButton";
import { Link } from "react-router-dom";

export function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [inprogress, setInprogress] = useState(false);

    const handleLogin = (e: any) => {
        e.preventDefault();
        setInprogress(true);
        setError("");
        login({ username: username.trim(), password: password.trim() })
            .then((res) => {
                if (res.succeeded) {
                    authService.storeAuthToken(res.authToken, res.refreshToken);
                    window.location.replace("/");
                } else {
                    setError("Incorrect username or password.");
                }
            })
            .catch((err) => {
                console.error(err);
                setError("Unknown Error. Please contact SME Plus support, if error continues.");
            })
            .finally(() => setInprogress(false));
    };

    return (
        <Card className="mt-5" style={{ width: "90%", maxWidth: "500px", margin: "0 auto" }}>
            <CardBody>
                <Form onSubmit={handleLogin}>
                    <FormGroup>
                        <Label>Username</Label>
                        <Input value={username} onChange={(e) => setUsername(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Password</Label>
                        <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </FormGroup>
                    <FormGroup className="text-center">
                        <SapButton type="submit" color="dark" submitting={inprogress}>
                            Log in
                        </SapButton>
                    </FormGroup>
                    <FormGroup className="text-start">
                        <Link className="no-underline" to="/account/forget-password">
                            Forget Password?
                        </Link>
                    </FormGroup>
                    <FormGroup>{error && <Alert color="danger">{error}</Alert>}</FormGroup>
                </Form>
            </CardBody>
        </Card>
    );
}
