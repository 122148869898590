import { coreApi } from "../../app/core-api";
import { Holiday, HolidayEdit } from "./config.types";

const controller = "configs";
const holidaycController = "holidays";

export const configApi = {
    getConfigValue,
    getHolidays,
    SaveHolidays,
};

export function getConfigValue(key: string) {
    return coreApi.get<string>(`${controller}/value/${key}`);
}

function getHolidays(year: number) {
    return coreApi.get<Holiday[]>(`${holidaycController}/${year}`);
}

function SaveHolidays(editList: HolidayEdit[]) {
    return coreApi.post(`${holidaycController}/EditList`, editList);
}
