import { coreApi } from "../../app/core-api";
import { EntityCreateResult, EntityUpdateResult, ListItem, PagedResult, PagedSearchFilter } from "../../app/types";
import {
    BulkLeaveGenerateResult,
    EmployeeLeaveBalance,
    EmployeeLeaveSet,
    EmployeeLeaveUpdateDto,
    LeaveFilter,
    LeaveRequest,
    LeaveRequestApproval,
    LeaveRequestCreateDto,
    LeaveRequestFilter,
    LeaveType,
} from "./leave.types";

export const leaveApi = {
    listLeaveTypes,
    getLeaveType,
    getDefaultLeaveTypeIds,
    createLeaveType,
    updateLeaveType,
    deleteLeaveType,
    getLeaveTypesSelectList,
    createDefaultLeaveTypes,
    bulkPopulateLeave,
    searchEmployeeLeave,
    getEmployeeLeave,
    saveEmployeeLeave,
    getLeaveApprovals,
    getCurrentUserLeaveRequests,
    getEmployeeLeaveBalance,
    getMyLeaveBalance,
    createLeaveRequest,
    getLeaveRequest,
    updateLeaveRequest,
    deleteLeaveRequest,
    approveLeaveRequest,
};

const controller = "Leave";

function listLeaveTypes() {
    return coreApi.get<LeaveType[]>(`${controller}/LeaveTypes`);
}

function getLeaveType(id: string) {
    return coreApi.get<LeaveType>(`${controller}/LeaveTypes/${id}`);
}

function getDefaultLeaveTypeIds() {
    return coreApi.get<ListItem[]>(`${controller}/LeaveTypes/Default`);
}

function createLeaveType(leaveType: LeaveType) {
    return coreApi.post<LeaveType>(`${controller}/LeaveTypes`, leaveType);
}

function updateLeaveType(id: string, leaveType: LeaveType) {
    return coreApi.put<LeaveType>(`${controller}/LeaveTypes/${id}`, leaveType);
}

function deleteLeaveType(id: string) {
    return coreApi.remove(`${controller}/LeaveTypes/${id}`);
}

function getLeaveTypesSelectList() {
    return coreApi.get<ListItem[]>(`${controller}/LeaveTypes/SelectList`);
}

function createDefaultLeaveTypes() {
    return coreApi.post(`${controller}/LeaveTypes/CreateDefaults`, {});
}

function bulkPopulateLeave(year: number) {
    return coreApi.post<BulkLeaveGenerateResult>(`${controller}/EmployeeLeaves/BulkPopulate/${year}`, {});
}

function searchEmployeeLeave(filter: PagedSearchFilter<LeaveFilter>) {
    return coreApi.get<PagedResult<EmployeeLeaveSet>>(`${controller}/EmployeeLeaves/Search`, {
        ...filter.filter,
        ...filter.pagination,
    });
}

function getEmployeeLeave(year: number, id: string) {
    return coreApi.get<EmployeeLeaveSet>(`${controller}/EmployeeLeaves/${year}/${id}`);
}

function saveEmployeeLeave(leaveSet: EmployeeLeaveUpdateDto) {
    return coreApi.post(`${controller}/EmployeeLeaves`, leaveSet);
}

function getLeaveApprovals(filter: PagedSearchFilter<LeaveRequestFilter>) {
    return coreApi.get<PagedResult<LeaveRequest>>(`${controller}/Requests`, {
        ...filter.filter,
        ...filter.pagination,
    });
}

function getCurrentUserLeaveRequests(filter: PagedSearchFilter<LeaveRequestFilter>) {
    return coreApi.get<PagedResult<LeaveRequest>>(`${controller}/Requests/CurrentUser`, {
        ...filter.filter,
        ...filter.pagination,
    });
}

function getEmployeeLeaveBalance(employeeId: string, year: number) {
    return coreApi.get<EmployeeLeaveBalance>(`${controller}/EmployeeLeaves/Balance/${employeeId}/${year}`);
}

function getMyLeaveBalance(year: number) {
    return coreApi.get<EmployeeLeaveBalance>(`${controller}/CurrentUser/Balance/${year}`);
}

function createLeaveRequest(request: LeaveRequestCreateDto, onBehalfOf?: boolean) {
    const url = onBehalfOf ? `${controller}/Requests/OnBehalfOf` : `${controller}/Requests`;
    return coreApi.post<EntityCreateResult>(url, request);
}

function updateLeaveRequest(id: string, request: LeaveRequestCreateDto) {
    return coreApi.put<EntityUpdateResult>(`${controller}/Requests/${id}`, request);
}

function getLeaveRequest(id: string) {
    return coreApi.get<LeaveRequest>(`${controller}/Requests/${id}`);
}

function deleteLeaveRequest(id: string) {
    return coreApi.remove(`${controller}/Requests/${id}`);
}

function approveLeaveRequest(data: LeaveRequestApproval) {
    return coreApi.post(`${controller}/Requests/Approve`, data);
}
