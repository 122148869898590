import { storageHelper, storageKeys } from "../../app/storage-helper";
import jwt_decode from "jwt-decode";
import { AuthUser } from "./auth.types";
import { authApi } from "./auth.api";
import { store } from "../../app/store";
import { setAuthUser } from "./auth.slice";

export const authService = {
    storeAuthToken,
    extractAuthUserFromToken,
    clearAuthToken,
    getAuthToken,
    refreshAuthToken,
};

export let AUTH_TOKEN = "";
export let AUTH_TOKEN_EXPIRY = 0;

function storeAuthToken(authToken: string, refreshToken: string) {
    storageHelper.setValue(storageKeys.authToken, authToken);
    storageHelper.setValue(storageKeys.authRefreshToken, refreshToken);

    const claims = extractRolesAndPermission(authToken);

    const tenantId = claims["sap/tenantid"];
    const exp = claims["exp"];

    cacheAuthToken(authToken, exp);

    storageHelper.setValue(storageKeys.tenantId, tenantId);
}

function extractAuthUserFromToken() {
    const authToken = storageHelper.getValue(storageKeys.authToken);
    if (authToken) {
        const claims = extractRolesAndPermission(authToken);

        const permissions = claims["sap/permission"] || [];
        const email = claims["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
        const givenName = claims["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"];
        const id = claims["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
        const tenantId = claims["sap/tenantid"];
        const role = claims["sap/role"];
        const exp = claims["exp"];

        cacheAuthToken(authToken, exp);

        storageHelper.setValue(storageKeys.tenantId, tenantId);

        const user: AuthUser = {
            id,
            email,
            role,
            permissions,
            givenName,
            tenantId,
        };

        return user;
    }
    return undefined;
}

function clearAuthToken() {
    storageHelper.removeValue(storageKeys.authToken);
    storageHelper.removeValue(storageKeys.tenantId);
    storageHelper.removeValue(storageKeys.authRefreshToken);

    AUTH_TOKEN = "";
    AUTH_TOKEN_EXPIRY = 0;
}

function extractRolesAndPermission(token: string) {
    var decoded = jwt_decode<any>(token);
    return decoded;
}

function cacheAuthToken(authToken: string, exp: number) {
    AUTH_TOKEN = authToken;
    AUTH_TOKEN_EXPIRY = exp;
}

function getAuthToken() {
    return AUTH_TOKEN || storageHelper.getValue(storageKeys.authToken) || "";
}

async function refreshAuthToken() {
    const refreshToken = storageHelper.getValue(storageKeys.authRefreshToken) || "";
    const response = await authApi.refreshAuthToken(getAuthToken(), refreshToken);

    const newAuthToken = response.authToken;

    storeAuthToken(newAuthToken, response.refreshToken);

    const user = extractAuthUserFromToken();

    store.dispatch(setAuthUser(user));

    return newAuthToken;
}
