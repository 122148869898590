import axios from "axios";
import { AUTH_TOKEN_EXPIRY, authService } from "../features/auth/auth.service";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

const apiNoAuth = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

function onRrefreshed(token: string) {
    refreshSubscribers.map((callback) => callback(token));
}

function addRefreshSubscriber(callback: (token: string) => void) {
    refreshSubscribers.push(callback);
}

api.interceptors.request.use(
    async (config) => {
        const authToken = authService.getAuthToken();

        if (authToken) {
            const now = Date.now() / 1000 - 5; // 5 seconds buffer

            if (AUTH_TOKEN_EXPIRY < now) {
                if (!isRefreshing) {
                    isRefreshing = true;
                    try {
                        const newAuthToken = await authService.refreshAuthToken();

                        config.headers!["Authorization"] = `Bearer ${newAuthToken}`;

                        isRefreshing = false;
                        onRrefreshed(newAuthToken);
                        refreshSubscribers = [];
                    } catch (error) {
                        isRefreshing = false;
                        refreshSubscribers = [];
                        throw error;
                    }
                }

                const retryOriginalRequest = new Promise((resolve) => {
                    addRefreshSubscriber((token: string) => {
                        config.headers!["Authorization"] = `Bearer ${token}`;
                        resolve(config);
                    });
                });

                return retryOriginalRequest;
            } else {
                config.headers!["Authorization"] = `Bearer ${authToken}`;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Setup Axios interceptors
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Redirect to login page
            window.location.replace("/account/login");
        }
        return Promise.reject(error);
    }
);

apiNoAuth.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Redirect to login page
            window.location.replace("/account/login");
        }
        return Promise.reject(error);
    }
);

export const coreApi = {
    post,
    get,
    put,
    patch,
    remove,
    download,
};

const axiosInstance = (noAuth: boolean) => (noAuth ? apiNoAuth : api);

function post<T>(path: string, data: any, noAuth = false) {
    return axiosInstance(noAuth)
        .post<T>(path, data)
        .then((r) => r.data);
}

function patch<T>(path: string, data: any) {
    return api.patch<T>(path, data).then((r) => r.data);
}

function put<T>(path: string, data: any) {
    return api.put<T>(path, data).then((r) => r.data);
}

function get<T>(path: string, queryParams?: any) {
    return api.get<T>(`${path}${queryParams ? createQueryString(queryParams) : ""}`).then((r) => r.data);
}

function remove<T>(path: string, queryParams?: any) {
    return api.delete<T>(`${path}${queryParams ? createQueryString(queryParams) : ""}`).then((r) => r.data);
}

function download(path: string, queryParams?: any) {
    return api
        .get(`${path}${queryParams ? createQueryString(queryParams) : ""}`, { responseType: "blob" })
        .then((r) => {
            const blob = new Blob([r.data]);
            const filename = getFileName(r.headers["content-disposition"]) || "file";
            saveFile(blob, filename);
        });
}

function saveFile(blob: Blob, filename: string) {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);
    if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
}

function createQueryString(obj: any) {
    const qs = Object.keys(obj)
        .map((key) => `${key}=${obj[key] === undefined ? "" : obj[key]}`)
        .join("&");

    return "?" + qs;
}

function getFileName(contentDisp: string) {
    const elems = contentDisp.split(" ");
    const filename = elems
        .find((e) => e.startsWith("filename="))
        ?.split("=")[1]
        .trimEnd()
        ?.slice(0, -1);
    return filename;
}
